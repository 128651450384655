<template>
  <div tile style="background-color: #43455e; height: 100%">
    <v-layout column>
      <v-row class="no-gutters pt-6">
        <p class="white--text text-left ml-10 subtitle-1 font-weight-medium" >
          Zonas y vacantes
        </p>
        <v-row v-if="zonesStateLoaded === false" class="no-gutters d-flex justify-end pr-6">
          <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
        </v-row>
      </v-row>
    </v-layout>

    <v-list flat dense color="#43455E">
      <v-list-group
        v-model="collapseExpand[zone.zone_id]"
        prepend-icon="mdi-map-marker-outline"
        v-for="zone in getAllZones.filter((zone) => zone.zone_is_active && !zone.zone_is_archive)"
        :key="zone.zone_id"
        @change="requestingAppointmentsByZone(`${zone.zone_id}`)"
        no-action
        :disabled="collapseExpand[zone.zone_id]"
      >
        <template v-slot:activator>
          <v-list-item-title class="white--text">
            {{ zone.company_zone_name }}
          </v-list-item-title>
        </template>
        <v-list-item
          class="white--text"
          v-for="vacancy in getAllVacancies"
          :key="vacancy.id"
          @click="requestAppointments(vacancy.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ vacancy.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import LoadCard from "../../mixins/LoadCard";
import ENUM from "@/store/enums";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "AppointmentSelector",
  mixins: [LoadCard],
  data: () => ({
    tab: null,
    currentTab: null,
    collapseExpand: [],
  }),
  methods: {
    ...mapActions(["requestsAllZones", "requestAllVacancies", "requestAppointments",]),
    ...mapGetters(["allAppointments"]),
    async requestingAppointmentsByZone(zoneId) {
      if (zoneId === this.selectedZone) {
        console.warn("Zone selected is the same!, look "+zoneId+" & "+this.selectedZone);
      } else {
        console.warn("Different zone ");
      }
      const allVacanciesRequested = await this.requestAllVacancies(zoneId);
      if(allVacanciesRequested) {
        this.requestAppointments(this.getAllVacancies.map(a => a.id)) 
      }
    }
  },
  computed: {
    ...mapGetters(["getAllZones", "getAllVacancies"]),
    ...mapState({
      zonesState: (state) => state.zones.status,
      appointmentsState: (state) => state.appointments.status,
      selectedZone: (state) => state.vacancies.selectedZone,
    }),
    zonesStateLoaded() {
      return this.zonesState === ENUM.LOADED;
    },
    currentView() {
      return this.$route.name;
    },
  },
  mounted() {
    this.requestsAllZones();
  },  
  beforeUpdate() {
    if (this.appointmentsState === ENUM.INIT) {
      //This could and need to be improved to avoid unnecessary requests
      this.requestingAppointmentsByZone(this.getAllZones[0].zone_id);
      const allZones =  this.getAllZones;
      allZones.forEach((element, index) => {
        if(index == 0) {
          this.collapseExpand[element.zone_id.toString()] = true;
        } else {
          this.collapseExpand[element.zone_id.toString()] = false;
        }
      });
    }
  },
};
</script>

<style>
  .v-list-group {
    background-color: #43455E   ;
    color : white !important;
  }
  .v-list-item {
    color: white 
  }
</style>