<template>
  <v-card class="ml-13" flat height="100vh">
    <v-container fluid grid-list class="ma-0 pa-0">
      <v-layout wrap d-flex justify-start class="ma-0 pa-0">
        <v-flex xs12 sm12 md2>
          <v-card flat tile height="100vh">
            <AppointmentSelector />
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md10 style="background:#F5F5F5">
          <v-card v-if="appointmentsStateLoaded === true" class="ma-5 pb-5" elevation="0">
            <v-card-title class="d-flex flex-column pt-8 pb-6">
              <div class="d-flex justify-start" style="width: 100%">
                <p class="text-body-1 ml-6 ma-0 font-weight-bold">Citas</p>
                <v-icon class="mr-2 ml-10" small color="#6F50FF"
                  >mdi-help-circle-outline</v-icon
                >
                <p class="text-caption ma-0">
                  Tus citas al día. Gestiona tus entrevistas.
                </p>
              </div>
              <div class="d-flex mt-4" style="width: 100%">
                <div class="d-flex align-center mr-16" style="width: 30%">
                  <v-icon small class="ml-5" color="#8739EB">mdi-magnify</v-icon>
                  <v-divider class="mx-3" inset vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    color="#6200EE"
                    class="caption shrink ml-2 pl-3 ma-0 pa-0 mb-2 text-capitalize"
                    style="width: 100%; background: #f7f8fa"
                    single-line
                    hide-details
                  >
                    <template v-slot:label class="caption">
                      <span class="caption ml-2">Buscar</span>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </v-card-title>
            <div v-if="appointmentsExists === false">
              <v-card
                v-for="(appointment, parent_index) in allAppointments"
                :key="parent_index"
                elevation="0"
                tile
                class="pl-4 pr-5 pb-2 mt-0"
              >
                <v-card
                  class="pa-1 ma-0"
                  tile
                  color="#F7F8FA"
                  elevation="0"
                  style="border-left: 4px solid #a3a1fb"
                >
                  <v-container fluid grid-list-md class="cont-items">
                    <v-layout row wrap d-flex justify-start>
                      <!-- Candidate-user icon -->
                      <v-flex d-flex justify-start align-center xs1 sm1 md1>
                        <v-skeleton-loader
                          type="avatar"
                          class="ma-0 pa-0"
                          :loading="loading"
                        >
                          <div class="d-flex align-center">
                            <v-avatar color="indigo" size="36">
                              <v-icon dark small> mdi-account-circle </v-icon>
                            </v-avatar>
                          </div>
                        </v-skeleton-loader>
                      </v-flex>
                      <!-- Candidate name and vacancy -->
                      <v-flex d-flex justify-start align-center xs6 sm6 md3>
                        <v-skeleton-loader
                          type="list-item-two-line"
                          class="ma-0 pa-0"
                          width="10vw"
                          :loading="loading"
                        >
                          <div class="d-flex flex-column caption align-start">
                            <p class="ma-0 pa-0 text-weight-lighter">
                              {{appointment.candidate_fullname}}
                            </p>
                            <p class="ma-0 pa-0 font-weight-bold">
                              {{appointment.candidate_vacancy_name}}
                            </p>
                          </div>
                        </v-skeleton-loader>
                      </v-flex>
                      <!-- Zone name -->
                      <v-flex d-flex justify-start align-center xs12 sm12 md3>
                        <v-skeleton-loader
                          type="list-item-two-line"
                          class="ma-0 pa-0"
                          width="28vw"
                          :loading="loading"
                        >
                          <div class="d-flex flex-column caption align-center ml-5">
                            <div class="d-flex">
                              <v-icon small color="#6E80F8"
                                >mdi-map-marker-outline</v-icon
                              >
                              <p class="ma-0 pa-0 ml-2 font-weight-bold">
                              {{appointment.candidate_zone_name}}
                              </p>
                            </div>
                          </div>
                        </v-skeleton-loader>
                      </v-flex>
                      <!-- Date & Hour -->
                      <v-flex d-flex justify-start align-center xs6 sm6 md2>
                        <v-skeleton-loader
                          type="list-item"
                          class="ma-0 pa-0"
                          width="15vw"
                          :loading="loading"
                        >
                          <div class="d-flex align-center caption" style="width: 100%">
                            <v-icon small color="#6E80F8">mdi-calendar</v-icon>
                            <p style="color: #808080" class="ma-0 pa-0 ml-2">{{appointment.candidate_appointment.date}}</p>
                          </div>
                          <div class="d-flex align-center caption" style="width: 100%">
                            <v-icon small color="#6E80F8">mdi-clock-outline</v-icon>
                            <p style="color: #808080" class="ma-0 pa-0 ml-2">{{appointment.candidate_appointment.start_hour}}</p>
                          </div>
                        </v-skeleton-loader>
                      </v-flex>
                      <!-- Reschedule btn -->
                      <v-flex xs12 sm12 md2>
                        <v-skeleton-loader
                          type="list-item"
                          class="ma-0 pa-0"
                          width="10vw"
                          :loading="loading"
                        >
                          <div class="d-flex align-center">
                            <v-dialog
                              v-model="rescheduleAppointmentsDialogs[parent_index]"
                              max-width="600px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  class="text-none caption mr-6"
                                  rounded
                                  outlined
                                  color="#8739EB"
                                  elevation="0"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="getAppointmentData(parent_index)"
                                >
                                  <v-icon class="mr-1" small color="#8739EB">
                                    mdi-calendar-edit
                                  </v-icon>
                                  Reagendar cita
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Reagendar cita</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container class="mt-4">
                                    <v-row  v-if="appointmentDataReady === true">
                                      <v-col cols="12">
                                        <v-btn
                                          v-for="(hours, index) in rescheduleVacancySchedule"
                                          :key="index"
                                          small
                                          class="ma-2"
                                          color="#A4A1FB"
                                          @click="test(hours.substring(0,5), index, parent_index)"
                                          :disabled="hours.substring(0,5) === allAppointments[parent_index].candidate_appointment.start_hour"
                                        >
                                          {{hours}}
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-date-picker
                                          v-model="rescheduleAppointmentDate"
                                          color="indigo"
                                          header-color="#A4A1FB"
                                        ></v-date-picker>
                                      </v-col>
                                    </v-row>
                                    <v-progress-circular v-else color="#A4A1FB" size="20" width="2" indeterminate></v-progress-circular>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="rescheduleAppointmentsDialogs[index] = false"
                                  >
                                    Close
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="rescheduleAppointment(`${index}`)"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-btn x-small fab color="#F86E80" dark elevation="0">
                              <v-icon dark small> mdi-close </v-icon>
                            </v-btn>
                          </div>
                        </v-skeleton-loader>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-card>
            </div>
            <div v-else>
              Aquí no hubo citas
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import LoadCard from "../../mixins/LoadCard";
import ENUM from "@/store/enums";
import { mapGetters, mapState, mapActions } from "vuex";
import AppointmentSelector from '../../components/App/AppointmentSelector.vue';

export default {
  components: { 
    AppointmentSelector 
  },
  mixins: [LoadCard],
  data: () => ({
    /* These 2 variables were added only to avoid temporarily error logs */
    search: "",
    editing: "",
    /* ----------------------------------------------------------------- */
    rescheduleAppointmentsDialogs: {},
    rescheduleAppointmentDate: null,
    rescheduleVacancySchedule: null,
    appointmentDataStatus: ENUM.INIT,
    validated: false,
  }),
  computed: {
    ...mapGetters(["allAppointments", "getAllVacancies", "getVacancyData"]),
    ...mapState({
      appointmentsState: state => state.appointments.status,
    }),
    appointmentsStateLoaded() {
      return this.appointmentsState === ENUM.LOADED;
    },
    appointmentDataReady() {
      return this.appointmentDataStatus === ENUM.LOADED;
    },
    appointmentsExists() {
      return this.allAppointments === "No candidates found!";
    },
    allAppointments() {
      let appointments = [];
      appointments = this.$store.getters.allAppointments;
      return appointments.filter((appointments) => {
        return (appointments.candidate_vacancy_name
          .toLowerCase()
          .match(this.search.toLowerCase())) || (appointments.candidate_fullname
          .toLowerCase()
          .match(this.search.toLowerCase()));
      });
    },
  },
  methods: {
    ...mapActions(["requestVacancyById"]),
    test(sth, index, parent_index) {
      const uwu = sth === this.allAppointments[parent_index].candidate_appointment.start_hour;
    },
    async getAppointmentData(index){
      this.appointmentDataStatus =  ENUM.LOADING;
      const currentAppointment = this.allAppointments[index];
      let currentVacancySchedule = null;
      //First of all we have to get the appointment info within the vacancy
      for (const vacancy in this.getAllVacancies) {
        const currentVacancy = this.getAllVacancies[vacancy];
        if(currentVacancy.id === currentAppointment.candidate_vacancy_id){
          await this.requestVacancyById(currentVacancy.id);
            currentVacancySchedule = this.getVacancyData.vacancy_schedule;           
        }
      }
      //With that info we could start setting the date to our datePickerComponent
      const weekDays = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
      const dateFromServer = new Date(currentAppointment.candidate_appointment.date);
      const dayName = weekDays[dateFromServer.getDay()];
      this.rescheduleAppointmentDate = currentAppointment.candidate_appointment.date;
      this.rescheduleVacancySchedule = currentVacancySchedule[dayName];
      // console.warn("The schedule in this day is: ", this.rescheduleVacancySchedule);
      this.appointmentDataStatus =  ENUM.LOADED;
      
      //After this, we take the hour and disable its button
    },
    rescheduleAppointment(index) {
      //This function could be directly an action later
      //right now is only to avoid error logs and close its dialog
      this.rescheduleAppointmentsDialogs[index] = false;
    },
  },

};
</script>

<style></style>
